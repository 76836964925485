import mapper from '../mappers/hiplayer'
import Logger from '../../core/Logger';
import Http from '../../core/Http';
import EventBus from '../../core/EventBus';
import {bundleLoaded, embedHibridPlayerScript, embedIMA, embedMatomo, proxySubs} from '../../helpers';
import Config from "../../core/Config";

/** @type {string}*/
const BUNDLE_CLASS_NAME = 'hibrid-vod-hibrid-player';
const player_version = process.env.APP_HI_PALYER_VERSION || 'v8.2.1'
let attempts = 0;

const validateLoaded = (callback) => setTimeout(() => {
    if (window.HiplayerLoader) return callback();
    if (attempts === 10) throw new Error('An error occur while booting Hibrid player');
    attempts++;
    return validateLoaded(callback);
}, 100);


export default {
    /**
     * @param player {string}
     * @param callback {function}
     */
    boot(player, callback) {
        if (bundleLoaded(BUNDLE_CLASS_NAME)) {
            Logger.info(`${BUNDLE_CLASS_NAME} already exist in DOM`);
            return Promise.resolve();
        }
        Logger.info('Loading a bundle for hibrid player');
        
        //Don't load bundle for HiPlayer, because we haven't bundle in embed  api
        embedHibridPlayerScript(player_version, BUNDLE_CLASS_NAME) && validateLoaded(callback)
    },
    /**
     * @param entry {{}}
     * @param attributes {{}}
     * @param element {HTMLElement}
     * @param extra_options {HTMLElement}
     * @param callback {function}
     */
    render(entry, attributes, element, extra_options, callback) {
        const sources = mapper(entry);
        const [{poster}] = sources;
        let options = {
            ...attributes.options, poster
        };
        options.hbAssetKey = entry.entry_id
        options.gaAssetKey = entry.entry_id

        if (entry.data && entry.data.subtitles) {
            let tracks = [];
            try{
                entry.data.subtitles.forEach(item => {
                    let obj = {}
                    obj.kind = "captions",
                    obj.srclang = item.language
                    obj.label = item.title
                    obj.src = proxySubs(item.url)
                    obj.default = item.language === entry.options.lang
                    tracks.push(obj)
                });
                if (tracks.length) {
                    options.textTrackEnabled =  true
                    options.textTracks = tracks
                }

            }catch (e){
                console.error(e.toString())
            }
        }

        if (entry.type === 'video' || entry.type === 'stream') {
            options.streamUrl =  sources[0].src;
            options.playlistEnabled = false;
        }else if (entry.type === 'playlist') {
            options.streamUrl = null;
            options.playlistEnabled = true;
            options.playlistUrls = sources;
        }
        for (let key in options) {
            if (options[key] === null) {
                options[key] = ``
            }
        }

        if(options.gaTrackingEnabled)
            options.gaTrackingId = attributes.ga_view_id


        if(entry.options.preroll) {
            options.imaEnabled = true
            options.imaAdTag = entry.options.preroll.tag 
            if(entry.options.preroll.type)
                options.imaAdType = entry.options.preroll.type
        }

        if(extra_options.dai) {
            options.daiEnabled = true
            options.daiApiKey =  extra_options.dai.apikey 
            options.daiAssetKey = extra_options.dai.assetkey
        }

        options = Object.assign(options, extra_options)        

        Logger.info(`Rendering player with options ${JSON.stringify(options)}`);

        let utf8Bytes = new TextEncoder().encode(JSON.stringify(options));
                
        HiplayerLoader.setup(
            element.id, window.btoa(String.fromCharCode(...utf8Bytes))
        )

        Logger.info(`Hibrid player rendered`);
    },

    remote(entry, attributes, element, extra_options, callback) {
        const sources = mapper(entry);
        const [{poster}] = sources;
        let options = {
            ...attributes.options, poster
        };
        options.hbAssetKey = entry.entry_id
        options.gaAssetKey = entry.entry_id


        if (entry.type === 'video' || entry.type === 'stream') {
            options.streamUrl =  sources[0].src;
            options.playlistEnabled = false;
        }
        for (let key in options) {
            if (options[key] === null) {
                options[key] = ``
            }

        }        

        entry.options = Object.assign(entry.options, extra_options)

        if (entry.options && entry.options.subtitles) {
            let tracks = [];
            try{
                entry.options.subtitles.forEach(item => {
                    let obj = {}
                    obj.kind = "captions",
                    obj.srclang = item.language
                    obj.label = item.title
                    obj.src = proxySubs(item.url)
                    obj.default = false
                    tracks.push(obj)
                });
                if (tracks.length) {
                    options.textTrackEnabled =  true
                    options.textTracks = tracks
                }

            }catch (e){
                console.error(e.toString())
            }
        }
        

        if(options.gaTrackingEnabled)
            options.gaTrackingId = attributes.ga_view_id

        Logger.info(`Rendering player with options ${JSON.stringify(options)}`);
        
        if(entry.options.preroll) {
            options.imaEnabled = true
            options.imaAdTag = entry.options.preroll.tag 
            if(entry.options.preroll.type)
                options.imaAdType = entry.options.preroll.type
        }

        if(entry.options.dai) {
            options.daiEnabled = true
            options.daiApiKey =  entry.options.dai.apikey
            options.daiAssetKey = entry.options.dai.assetkey
        }

        options = Object.assign(options, extra_options)     

        console.log(options);
        

        let utf8Bytes = new TextEncoder().encode(JSON.stringify(options));
                
        HiplayerLoader.setup(
            element.id, window.btoa(String.fromCharCode(...utf8Bytes))
        )
        
        Logger.info(`Hibrid player rendered`);
    }
}
